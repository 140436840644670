<template>
	<div class="box box_mm">
		<div class="box_main">
			<div class="search_box">
				<div class="search_item">
					<span style="width: 0.8rem;">文件标题：</span>
					<el-input v-model="searchinput" placeholder="请输入" style="width: 2rem;"></el-input>
				</div>
				<div class="search_item">
					<el-button type="primary" @click="search">搜索</el-button>
					<!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
				</div>
			</div>
			<div class="box_cont">
				<div class="btn_list">
				</div>
				<div style="height: 76vh;box-sizing: border-box;">
					<MyTable ref="zjlist" :tableData="tableData" :tableHeader="tableHeader" :showbm="true"
						:czwidth="180" @getdata="gettable" @changswitch="changswitch">
						<template slot="btns" slot-scope="{ scope }">
							<el-button @click="showdetail(scope.row)" type="primary" size="mini">详情</el-button>
							<el-button @click="showcs(scope.row)" type="success" size="mini">抄送</el-button>
						</template>
					</MyTable>
				</div>
			</div>
		</div>		
		
		<!--抄送弹出-->
		<el-dialog title="选择抄送人员" :visible.sync="showtc" width="40%" :before-close="setsheng">
			<div>
				<el-form ref="ruleForm" class="demo-ruleForm">
					  <el-form-item label="选择抄送人员" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select()">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name}}
					  		</div>
					  </el-form-item>
					  <!-- <el-form-item label="选择交办人" required>
					  		<div style="display: flex;align-items: center;">
					  			<el-button type="primary" size="small" @click="select(3)">选择</el-button>								
					  		</div>
					  		<div>
					  			<span style="color: #999;">已选人员：</span>{{user_name3}}
					  		</div>
					  </el-form-item> -->
				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="setsheng">取 消</el-button>
				<el-button type="success" @click="queren">确 认</el-button>
			</span>
		</el-dialog>
		
		<detail ref="mydetail"></detail>
		<selectusers ref="myselectuser" @selectedry="selectedry"></selectusers>
	</div>
</template>

<script>
	import detail from '../components/detail.vue'
	import selectusers from '@/components/select_users.vue'
	export default {
		components:{ detail,selectusers },
		data() {
			return {
				searchinput: '',
				tableHeader: [{
						prop: 'id',
						name: '序',
						width: '50'
					},
					{
						prop: 'name',
						name: '文件标题',
						width: ''
					},
					{
						prop: 'fhuser_name',
						name: '复核人',
						width: '120'
					},
					{
						prop: 'qfuser_name',
						name: '签发人',
						width: '120'						
					},
					{
						prop: 'jjcd',
						name: '加急程度',
						width: '120',
						type:'jjcd'
					},
					{
						prop: 'addtime',
						name: '提交时间',
						width: '150'
					}
				],
				tableData: [],
				
				showtc:false,
				user_name:'',
				user_id:'',//抄送人
				czid:'',
			}
		},
		mounted() {
			this.gettable()
		},
		methods: {	
			queren(){
				console.log(this.user_name)
				console.log(this.user_id)
				console.log(this.czid)
				this.$post({
					url: '/api/matterfwen/chaosong',
					params: {
						id:this.czid,
						csr:this.user_id
					}
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
					this.setsheng()
				})
				
			},
			showcs(row){
				this.showtc = true
				this.czid = row.id
			},
			setsheng(){
				this.czid = ''
				this.user_name = ''
				this.user_id = ''//抄送人
				this.showtc = false
			},
			select(){
				
				let cxidlist = []
				let cxnamelist = []
				cxidlist = this.user_id?this.user_id.split(','):[]
				cxnamelist = this.user_name?this.user_name.split(','):[]	
				let list = []
				if(cxidlist.length>0){
					cxidlist.forEach((item,index)=>{
						let obj={
							name:cxnamelist[index],
							id:Number(item)
						}
						list.push(obj)
					})
				}	
				this.$refs.myselectuser.selectuser = list.length>0?list:[]
				this.$refs.myselectuser.dialogVisible = true
			},
			selectedry(e){
				this.user_id = e.map((item)=>{ return item.id })
				this.user_id = this.user_id.join(',')
				this.user_name = e.map((item)=>{ return item.name })
				this.user_name = this.user_name.join(',')
			},
			showdetail(row){
				this.$post({
					url: '/api/matterfwen/details',
					params: {
						id:row.id
					}
				}).then((res) => {
					this.$refs.mydetail.info = res
					this.$refs.mydetail.dialogVisible = true
				})
			},
			postdata(row) {
				this.$confirm('您确定归档吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$post({
						url: '/api/matter/cundang',
						params: {
							id:row.id
						}
					}).then((res) => {
						this.$message.success('操作成功')
						this.gettable()
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				})
			},
			showeidt(row) {
				this.$router.push('/system/get_file/edit?id=' + row.id)
			},
			changswitch(row) {
				this.$post({
					url: '/api/room/edit',
					params: row
				}).then((res) => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					})
					this.gettable()
				})
			},
			search() {
				this.$refs.zjlist.inputpage = 1
				this.gettable()
			},
			gettable() {
				this.$post({
					url: '/api/matterfwen/index',
					params: {
						name: this.searchinput,
						p: this.$refs.zjlist.inputpage,
						size: this.$refs.zjlist.pagesize,
						type: 9
					}
				}).then((res) => {
					this.tableData = res.list
					this.$refs.zjlist.total = res.count
				})
			},
			addnew() {
				this.$router.push('/system/get_file/add')
			},
			showdate(row) {
				this.$refs.myroomdate.dialogVisible = true
			},
		}
	}
</script>

<style lang="scss" scoped>
	@import 'daichaos.scss';
</style>